<template>
  <div class="mobile-summary d-none">
    <div class="mb-2 mx-5">
      <step-indicator></step-indicator>
    </div>
    <div class="summary-header pt-4 pb-2 px-4">
      <div class="summary-header-info">
        <div class="d-flex justify-content-between align-content-center">
          <div class="">
            <div class="pt-2" v-show="expanded">
              <h5>Cleaning Breakdown</h5>
            </div>
            <p class="my-0 pt-1" v-show="!expanded"><i class="fas fa-house mr-1"></i>{{cleaningPlan}}</p>
          </div>
          <div class="d-flex">
            <div class="ml-auto font-16 text-nowrap" v-show="!expanded">
             <h6 class="pt-2 mr-2 mb-0"><i class="fal fa-dollar-sign"></i> {{total}} </h6>
            </div>
            <div class="ml-auto summary-collapse font-18">
              <a v-b-toggle.collapse-summary>
                <i :class="expanded ? 'fas fa-times' : 'fas fa-chevron-down'"></i>
              </a>
            </div>
          </div>
        </div>
        <p class="my-0" v-show="!expanded">{{property}}</p>
      </div>
    </div>
    <b-collapse id="collapse-summary" v-model="expanded" class="summary-body px-4">
      <div class="breakdown-summary-wrapper--property">
        <ul class="fa-ul">
          <li>
            <span class="fa-li"><i class="fas fa-house"></i></span>
            <div class="breakdown-item">
              <h6>{{ cleaningPlan }}</h6>
              <p>{{ property }}</p>
              <div v-for="(extra, idx) in extras" :key="idx" class="float-parent gray-text clearfix mb-2">
                <extra-for-summary :extra="extra" class="float-left"></extra-for-summary>
              </div>
            </div>
          </li>
          <li v-if="isRecurring">
            <span class="fa-li"><i class="fas fa-sync-alt"></i></span>
            <div class="breakdown-item">
              <h6>{{ recurrence }}</h6>
              <p>{{ recurrencePrice }}</p>
            </div>
          </li>
          <li v-if="isStartPicked">
            <span class="fa-li"><i class="fas fa-calendar-alt"></i></span>
            <div class="breakdown-item">
              <h6>{{ start }}</h6>
              <p></p>
            </div>
          </li>
          <li v-if="flexibleDescription">
            <span class="fa-li"><i class="fas fa-question-circle"></i></span>
            <div class="breakdown-item">
              <h6>{{ flexibleDescription }}</h6>
              <p></p>
            </div>
          </li>
          <li v-if="chargeParkingFee">
            <span class="fa-li"><i class="fas fa-question-circle" v-b-tooltip.hover.html
                :title="parkingFeeTooltip"></i></span>
            <div class="breakdown-item">
              <h6>Parking Fee:<span class="ml-1">$4.00</span></h6>
              <p></p>
            </div>
          </li>
          <li v-if="discountInfo">
            <span class="fa-li"><i class="fas fa-percentage"></i></span>
            <div class="breakdown-item">
              <h6><span class="ml-1">{{ discountInfo }}</span></h6>
              <p>{{ discountInfo2 }}<button class="btn btn-link text-danger" @click="$emit('remove:promo_code')">Remove Coupon</button></p>
            </div>
          </li>
        </ul>
        <div v-if="!discountInfo">
          <div class="breakdown-item discount-input mw-90 mx-auto">
            <b-input-group>
              <b-form-input v-model="promo_code_m" placeholder="Have a promo code?" type="text"></b-form-input>
              <b-input-group-append>
                <b-button variant="" @click="$emit('save:promo_code')" class="btn btn-xs btn-primary white-text ">Apply</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <hr />
        <ul class="fa-ul">
          <li>
            <span class="fa-li"></span>
            <div class="breakdown-item">
              <a v-b-toggle.collapse-services>(<i class="fal fa-plus"></i> Included Services)</a>
              <b-collapse id="collapse-services">
                <div class="breakdown-services">
                  <h6 class="my-2">Bedrooms and All Living Spaces</h6>
                  <ul class="list-unstyled">
                    <li class="green-text"> - WASHING BASEBOARDS</li>
                    <li class="green-text"> - WASHING CEILING FANS</li>
                    <li> - DUSTING CEILING FANS</li>
                    <li> - SANITIZING ALL SURFACES</li>
                    <li> - WIPING DOWN FURNITURE</li>
                    <li> - CLEANING LIGHT SWITCHES & DOORKNOBS</li>
                    <li> - DUSTING ELECTRONICS</li>
                    <li> - BED MAKING</li>
                    <li> - LIGHT ORGANIZING</li>
                    <li> - WIPING DOWN WINDOWSILLS</li>
                    <li> - VACUUMING ALL CARPET & RUGS</li>
                    <li> - TAKING OUT TRASH</li>
                    <li> - MOPPING FLOORS</li>
                    <li> - CLEANING STAIRCASES</li>
                    <li> - CLEANING HALLWAYS</li>
                    <li> - CLEANING LAUNDRY ROOM</li>
                  </ul>
                </div>
                <div class="breakdown-services">
                  <h6 class="my-2">Kitchen</h6>
                  <ul class="list-unstyled">
                    <li class="green-text"> - CLEANING INSIDE OVEN</li>
                    <li> - CLEANING CABINET EXTERIORS</li>
                    <li> - WIPING DOWN APPLIANCES</li>
                    <li> - WASHING SINKS</li>
                    <li> - CLEANING COUNTERTOPS</li>
                    <li> - CLEANING INSIDE MICROWAVE</li>
                    <li> - SOAP SCUM REMOVAL</li>
                    <li> - CLEANING FRIDGE EXTERIOR</li>
                  </ul>
                </div>
                <div class="breakdown-services">
                  <h6 class="my-2">Bathrooms</h6>
                  <ul class="list-unstyled">
                    <li class="green-text"> - MOLD & MINERAL REMOVAL</li>
                    <li> - SOAP SCUM REMOVAL</li>
                    <li> - CLEANING CABINET EXTERIORS</li>
                    <li> - CLEANING COUNTERTOPS</li>
                    <li> - CLEANING MIRRORS</li>
                    <li> - WASHING SINKS</li>
                    <li> - CLEANING TUBS & SHOWERS</li>
                    <li> - POLISHING STAINLESS STEEL</li>
                  </ul>
                </div>
              </b-collapse>
            </div>
          </li>
          <li>
            <span class="fa-li"></span>
            <div class="d-flex breakdown-item pb-2">
              <div>Subtotal: </div>
              <div class="ml-auto">
                <h6><strong><i class="fal fa-dollar-sign"></i> {{ total }}</strong></h6>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </b-collapse>
    <slot name="additional-section"></slot>
  </div>
</template>
<script>
import Base from './base.vue'

export default {
  extends: Base,
  data() {
    return {
      expanded: false
    }
  }
}
</script>
